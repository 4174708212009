<template>
  <div class="mt-4">
    <v-btn small dark class="mr-5 ocupation-only__button" color="#466BE3" @click="dialog = true">
      AGREGAR PUESTO
    </v-btn>
    <v-btn small dark class="mr-5 ocupation-only__button" color="#466BE3" @click="wall = true">
      AGREGAR COLUMNA
    </v-btn>
    <!-- <v-btn small dark class="mr-5 ocupation-only__button" @click="addMap()"> AGREGAR PLANO </v-btn> -->
    <v-btn small dark class="mr-5 ocupation-only__button" color="#466BE3" @click="save()">
      GUARDAR
    </v-btn>
    <v-btn small dark class="mr-5 ocupation-only__button" color="#466BE3" @click="magnifying()">
      {{ isVisible ? "CANCELAR LUPA" : "ACTIVAR LUPA" }}
    </v-btn>
    <div :class="!isVisible ? 'mt-8' : 'mt-8 no-line'" id="containment-wrapper" ref="printMe">
      <section :class="isVisible ? 'display-none' : 'visible'">
        <template v-for="data in listPositions">
          <div @click="openPos()" :key="data.id" v-html="data.html" :class="data.statusId === 7 && 'hidden'"></div>
        </template>

        <div class="new-pos">
          <template v-for="data in listAddPos">
            <div @click="openPos()" :key="data.positionNumber" :id="'draggable' + data.positionNumber" :class="'draggable ui-widget-content position txt-pos ' + data.type
              ">
              {{ data.positionNumber.includes("-") ? "" : data.positionNumber }}
            </div>
          </template>
        </div>
        <!-- <div id="droppable" class="ui-widget-header">
          <i class="fa-solid fa-trash-can trash-icon"></i>
        </div> -->
      </section>

      <template>
        <vue-photo-zoom-pro :high-url="output" :class="isVisible
            ? 'visible magnifying-style'
            : 'display-none magnifying-style'
          " :type="'circle'" :scale="2" :height="150" :width="150">
          >
          <img :src="output" class="magnifying-style" />
          <div></div>
        </vue-photo-zoom-pro>
      </template>

      <div class="grid"></div>
    </div>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog2" persistent max-width="600px">
          <v-card>
            <v-card-text class="text-h5 confirm-text">
              <b>¿Está seguro que desea eliminar</b>
              <br />
              <b>el puesto de trabajo número {{ numberToDelete }}?</b>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog2 = false">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="deletePos()">
                <b>Eliminar</b>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Creación puesto de trabajo</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Numero de puesto de trabajo" v-model="num" required></v-text-field>
                  </v-col>
                  <v-alert dense outlined type="error" width="100%" v-if="alert">
                    <strong>
                      {{
                        num === null
                        ? "Debe ingresa un puesto de trabajo"
                        : "Este puesto de trabajo ya se encuentra creado"
                      }}
                    </strong>
                  </v-alert>
                  <v-radio-group v-model="radioGroup" mandatory>
                    <v-radio value="size-y">
                      <template v-slot:label>
                        <div class="size-s-container">
                          <div class="cuadrado">
                            <div class="division"></div>
                            <div class="division" style="left: 50%;"></div>
                            <div class="division" style="top: 50%;"></div>
                            <div class="division" style="left: 50%; top: 50%;"></div>
                          </div>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="size-s">
                      <template v-slot:label>
                        <div class="size-s"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-m">
                      <template v-slot:label>
                        <div class="size-m"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-l">
                      <template v-slot:label>
                        <div class="size-l"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-l2">
                      <template v-slot:label>
                        <div class="size-l2"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-xl">
                      <template v-slot:label>
                        <div class="size-xl"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-xl2">
                      <template v-slot:label>
                        <div class="size-xl2"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-xl3">
                      <template v-slot:label>
                        <div class="size-xl3"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-xl4">
                      <template v-slot:label>
                        <div class="size-xl4"></div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Cerrar
              </v-btn>
              <v-btn color="blue darken-1" text @click="addPos()">
                Crear
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="wall" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Creación de columnas</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-radio-group v-model="radioGroupWall" mandatory>
                    <v-radio value="size-wall-s">
                      <template v-slot:label>
                        <div class="size-wall-s"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-wall-m">
                      <template v-slot:label>
                        <div class="size-wall-m"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-wall-l">
                      <template v-slot:label>
                        <div class="size-wall-l"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-wall-l2">
                      <template v-slot:label>
                        <div class="size-wall-l2"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-wall-xl">
                      <template v-slot:label>
                        <div class="size-wall-xl"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-wall-xl2">
                      <template v-slot:label>
                        <div class="size-wall-xl2"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-wall-xl3">
                      <template v-slot:label>
                        <div class="size-wall-xl3"></div>
                      </template>
                    </v-radio>
                    <v-radio value="size-wall-xl4">
                      <template v-slot:label>
                        <div class="size-wall-xl4"></div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="wall = false">
                Cerrar
              </v-btn>
              <v-btn color="blue darken-1" text @click="addWall()">
                Crear
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-overlay :value="loading.all">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>

    <div class="screen-size">
      <v-card style="border-radius: 15px">
        <v-card-title class="pt-6">
          <v-layout justify-center>
            <img class="ml-6" width="80" height="80" src="../../assets/icon/info.svg" />
          </v-layout>
          <v-btn @click="$router.go(-1)" color="#466BE3" icon x-small style="display: flex; left: 0px; bottom: 45px">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="login___txt_title">¡Oh no!</p>
          <p class="login___txt_sms pb-0 mb-0">
            Esta vista está únicamente disponible en pantallas de resoluciones
            superiores a 1200px
          </p>
        </v-card-text>

        <v-card-actions class="pb-8">
          <v-row>
            <v-col class="mb-0 pb-0" cols="12">
              <slot name="actions"></slot>
            </v-col>
            <v-col cols="12">
              <v-btn block color="#466BE3" dark rounded @click="$router.go(-1)" class="alert__button">
                Regresar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "./../../plugins/jquery-ui";
import Api from "@/utils/api";
import vuePhotoZoomPro from "vue-photo-zoom-pro";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
export default {
  components: {
    vuePhotoZoomPro,
  },
  data() {
    return {
      createPos: false,
      updatePos: false,
      dialog: false,
      dialog2: false,
      loading: {
        all: false,
      },
      listAddPos: [],
      listPositions: [],
      radioGroup: null,
      radioGroupWall: null,
      num: null,
      alert: false,
      output: null,
      isVisible: false,
      numberToDelete: null,
      floorId: null,
      wall: false,
    };
  },
  methods: {
    getBlueprint() {
      Api.Auth()
        .getFloorById(this.floorId)
        .then((res) => (this.blueprint = res.data.data.blueprint.image))
        .catch((err) => console.log(err))
        .finally(() => {
          let containment = document.getElementById("containment-wrapper");
          containment.style.backgroundImage = `url('${this.blueprint}')`;
        });
    },
    async magnifying() {
      if (!this.isVisible) {
        this.loading.all = true;
        const el = this.$refs.printMe;
        const options = {
          type: "dataURL",
          width: "100%",
          height: "100%",
        };
        this.output = await this.$html2canvas(el, options);
        this.isVisible = true;
        this.loading.all = false;
      } else {
        this.loading.all = true;
        const el = this.$refs.printMe;
        const options = {
          removeContainer: true,
        };
        this.output = await this.$html2canvas(el, options);
        this.isVisible = false;
        this.loading.all = false;
      }
    },
    save() {
      this.loading.all = true;
      if (this.updatePos) {
        let positions = [];
        this.listPositions.forEach((element) => {
          let newTag = document.querySelector(
            `#draggable${element.positionNum}`
          ).outerHTML;
          let position = {
            positionNum: element.positionNum,
            html: newTag,
            statusId: element.statusId,
            type: element.type,
          };
          positions.push(position);
        });
        let updateData = {
          floorId: this.floorId,
          positions,
        };
        Api.Auth()
          .createAndUpdateWorkplace(updateData)
          .then(() => {
            // this.updatePos = false;
            this.loading.all = false;
          });
      }
      if (this.createPos) {
        let positions = [];
        this.listAddPos.forEach((element) => {
          let tag = document.querySelector(
            `#draggable${element.positionNumber}`
          ).outerHTML;
          let position = {
            positionNum: element.positionNumber,
            html: tag,
            statusId: 1,
            type: element.type,
          };
          positions.push(position);
        });
        let createData = {
          floorId: this.floorId,
          positions,
        };
        Api.Auth()
          .createAndUpdateWorkplace(createData)
          .then(() => {
            this.createPos = false;
            this.loading.all = false;
          });
      }
    },
    openPos() {
      console.log("Abriendo");
      console.log("Abriendo nuevamente");
    },
    dropToDelete() {
      var app = this;
      $("#droppable").droppable({
        drop: function (event, ui) {
          app.dialog2 = true;
          app.numberToDelete = ui.draggable[0].textContent.trim();
        },
        hoverClass: "trash",
      });
    },
    deletePos() {
      this.dialog2 = false;
      this.loading.all = true;
      Api.Auth()
        .deleteWorkplace(this.numberToDelete)
        .then(() => {
          this.numberToDelete = null;
          this.getSiteMap();
        });
    },
    updateDrag() {
      $(() => {
        for (let i = 0; i < this.listPositions.length; i++) {
          $("#draggable" + this.listPositions[i].positionNum).draggable({
            containment: "#containment-wrapper",
            scroll: true,
            drag: function (event, ui) {
              $("#posx").text(ui.position.left);
              $("#posy").text(ui.position.top);
            },
          });
        }
      });
      // Esto quiero que sea dinamico, que si no se mueven elemento que ya vienen de la base de datos, no pase a true para que no se actualicen todos los elementos
      this.updatePos = true;
      $(() => {
        for (let i = 0; i < this.listAddPos.length; i++) {
          $("#draggable" + this.listAddPos[i].positionNumber).draggable({
            containment: "#containment-wrapper",
            scroll: true,
            drag: function (event, ui) {
              $("#posx").text(ui.position.left);
              $("#posy").text(ui.position.top);
            },
          });
        }
      });
    },
    addPos() {
      if (this.num === null) {
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
        }, 2000);
      } else {
        Api.Auth()
          .getWorkplaceByPositionNumber(this.floorId, this.num)
          .then((res) => {
            if (res.data.data.positionNum && res.data.data.statusId === 1) {
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
              }, 2000);
            }
          })
          .catch((error) => {
            let isNew = this.listAddPos.some(
              (element) => element.positionNumber === this.num
            );
            if (!isNew) {
              let num = {
                positionNumber: this.num,
                type: this.radioGroup,
              };
              this.listAddPos.push(num);
              this.updateDrag();
              this.radioGroup = null;
              this.num = null;
              this.createPos = true;
              this.dialog = false;
              this.alert = false;
            } else {
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
                this.num = null;
              }, 2000);
            }
          });
      }
    },
    getSiteMap() {
      this.loading.all = true;
      Api.Auth()
        .getSiteMap(this.$route.params.id)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listPositions = res.data.data;
            this.updateDrag();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.all = false;
          this.getBlueprint();
        });
    },
    addWall() {
      let characters = "abcdefghijklmnopqrstuvwxyz";
      let randomCharacter =
        characters[Math.floor(Math.random() * characters.length - 1) + 1];
      let number = Math.floor(Math.random() * 1000) + 1 + `-${randomCharacter}`;
      Api.Auth()
        .getWorkplaceByPositionNumber(number)
        .then((res) => {
          number = Math.floor(Math.random() * 1000) + 1 + `-${randomCharacter}`;
        })
        .catch((error) => {
          let isNew = this.listAddPos.some(
            (element) => element.positionNumber === number
          );
          if (!isNew) {
            let num = {
              positionNumber: number,
              type: this.radioGroupWall,
            };
            this.listAddPos.push(num);
            this.updateDrag();
            this.radioGroupWall = null;
            this.createPos = true;
            this.wall = false;
          }
        });
    },
  },
  mounted() {
    this.updateDrag();
    this.getSiteMap();
    this.dropToDelete();
    this.floorId = this.$route.params.id;
  },
};
</script>

<style scoped>
.screen-size {
  position: absolute;
  top: 25px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #33333363;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.screen-size-text {
  font-family: "AsapSemiBold";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  color: #f2f4ff;
}

.grid {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/gridv2.png");
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.magnifying-style {
  height: 100%;
  width: 100%;
}

.confirm-text {
  padding-bottom: 0;
  padding-top: 20px;
}

#droppable {
  width: 40px;
  height: 40px;
  float: left;
  margin: 10px;
  border: 1px dashed #466be3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trash-icon {
  font-size: 25px;
}

.trash {
  background-color: #f19696;
}

.no-line {
  border: 0 !important;
}

.display-none {
  display: none;
}

.visible {
  visibility: visible;
}

.hidden div {
  visibility: hidden;
}

.c-txt-pos {
  width: 30px;
  height: 24px;
  margin: 0px;
  padding-top: 3px;
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  border: 1px solid red;
  padding: 2px;
  padding-left: 1px;
}

.size-s {
  width: 14px !important;
  height: 12px !important;
  border: 1px solid #466be3;
}

.size-m {
  width: 14px !important;
  height: 24px !important;
  border: 1px solid #466be3;
}

.size-l {
  width: 28px !important;
  height: 12px !important;
  border: 1px solid #466be3;
}

.size-l2 {
  width: 56px !important;
  height: 12px !important;
  border: 1px solid #466be3;
}

.size-xl {
  width: 28px !important;
  height: 24px !important;
  border: 1px solid #466be3;
}

.size-xl2 {
  width: 56px !important;
  height: 24px !important;
  border: 1px solid #466be3;
}

.size-xl3 {
  width: 28px !important;
  height: 56px !important;
  border: 1px solid #466be3;
}

.size-xl4 {
  width: 56px !important;
  height: 56px !important;
  border: 1px solid #466be3;
}

.size-wall-s {
  width: 14px !important;
  height: 12px !important;
  background-color: #000000;
}

.size-wall-m {
  width: 14px !important;
  height: 24px !important;
  background-color: #000000;
}

.size-wall-l {
  width: 28px !important;
  height: 12px !important;
  background-color: #000000;
}

.size-wall-l2 {
  width: 56px !important;
  height: 12px !important;
  background-color: #000000;
}

.size-wall-xl {
  width: 28px !important;
  height: 24px !important;
  background-color: #000000;
}

.size-wall-xl2 {
  width: 56px !important;
  height: 24px !important;
  background-color: #000000;
}

.size-wall-xl3 {
  width: 28px !important;
  height: 56px !important;
  background-color: #000000;
}

.size-wall-xl4 {
  width: 56px !important;
  height: 56px !important;
  background-color: #000000;
}

.txt-pos {
  margin: 0px !important;
  padding-top: 3px !important;
  font-size: 5.5px !important;
  font-weight: bold !important;
  font-family: sans-serif !important;
  border: 1px solid #466be3;
  padding: 0px !important;
  padding-left: 0px !important;
  width: 18px;
}

.draggable {
  width: 18px;
  height: 14px;
  float: left;
  margin: 2px;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#containment-wrapper {
  /* width: 100%; */
  width: 1000px;
  /* height: 1010px; */
  height: 705px;
  border: 2px solid #ccc;
  /* padding: 5px; */
  background-size: 100%;
  background-position: bottom;
  margin-bottom: 24px;
  position: relative;
  z-index: 2;
}

.new-pos {
  /* margin-top: 15px; */
  width: 100%;
  height: 100px;
  border-bottom: 3px dashed #466be3;
  /* padding: 5px; */
  /* Aqui no puede haber padding porque al momento de guardar y comsumir nuevamente la api, se van a mover los elementos */
}

/* .position {
  border: 1px red solid;
  padding: 3px;
} */

h3 {
  clear: left;
}

* {
  box-sizing: border-box;
  margin-top: 0px;
}

body,
html {
  font-family: Sans-Serif;
  width: 100%;
  height: 100%;
}

body {
  padding: 0px;
  background-color: #333;
  color: #fff;
  margin: 0px;
  padding: 0px;
}

.magnify {
  z-index: 20;
  border: 3px solid #999;
  position: relative;
  width: 900px;
  height: 640px;
  margin: 0px auto;
  overflow: hidden;
}

.magnify img {
  display: block;
}

.element_to_magnify {
  position: relative;
  width: 900px;
  height: 640px;
}

.element_to_magnify a {
  display: block;
  position: absolute;
  top: 80px;
  right: 80px;
  background-color: rgba(200, 200, 200, 0.25);
  padding: 4px;
  font-size: 18px;
  color: #fff;
}

.element_to_magnify img {
  width: 100%;
  height: auto;
}

.magnify_glass {
  z-index: 300;
  position: absolute;
  border: 4px solid #ccc;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.ocupation-only__button:hover {
  background-color: #324ca1 !important;
}

.size-s-container {
    display: inline-block; /* Asegura que el contenedor se ajuste al tamaño del contenido */
  }

  .cuadrado {
    position: relative;
    width: 14px;
    height: 12px;
    border: 1px solid #466be3;
    overflow: hidden;
  }

  .division {
    position: absolute;
    width: 50%;
    height: 50%;
    border: 1px solid #466be3;
  }

@media (min-width: 1201px) {
  .screen-size {
    display: none;
  }
}

@media (max-width: 1023px) {
  .screen-size {
    top: 0;
  }
}
</style>

<template>
  <div class="restrict page pl-8 pr-8 d-flex align-center flex-column mt-14">
    <div class="text-left" style="width: 100%">
      <h1 class="ocupation__title">Ocupación</h1>
    </div>
    <only></only>
  </div>
</template>
<script>
import Only from "./Only";
export default {
  components: {
    Only,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
@import "./../../assets/css/main.less";
.ocupation__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}
</style>
